<template>
    <div class="thanks">感谢！</div>
</template>

<script>
    export default {
        name: "Thanks"
    }
</script>

<style scoped>
.thanks{

    text-align: center;
    font-size: 30px;
    color: darkgray;
}
</style>
